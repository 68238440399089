.container {
  display: flex;
  height: 100vh; 
}

.column-menu {
  flex-shrink: 0;
  width: auto;
  position: fixed;
  width: 15em;
  padding: 1em;
}

.column-content {
  flex-grow: 1;
  margin-left: 15em;
}

@media screen and (max-width: 35em) {
  .column-menu {
    visibility: collapse;
    width: 0;
  }

  .column-content {
    margin-left: 0;
  }
}

@media screen and (min-width: 35em) {
  #mobile-navigation {
    display: none;
  }
}

.page {
  margin: 0;
  min-height: 90vh;
}

.page-header {
  display: flex;
  margin-top: 0.5em;
}

.page-header h1 {
  display: inline-block;
  font-size: 1.5em;
  font-weight: lighter;
  margin: 0.2em 0 0.2em 0.3em;
}

.page-logo {
  height: 2.6em;
}

.page-in-background {
  overflow-y: hidden;
}

.movies-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.movies-no-item {
  display: block;
  text-align: center;
  margin-top: 5em;
  font-family: "Solway", sans-serif;
  font-weight: 100;
  color: var(--highlight-color);
}

/* movie */

.poster-dummy {
  height: 15em;
  width: 10em;
  border-radius: 0.8em;
  border: solid 1px rgba(53, 53, 53, 0.796);
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
}

.movie-frame {
  overflow: hidden;
  position: relative;
  min-height: 18em;
  max-width: 13em;
}

.clickable-poster {
  overflow: hidden;
  border-radius: 0.8em;
  border: solid 1px rgba(53, 53, 53, 0.796);
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  background-color: rgba(0, 0, 0, 0.45);
}

.clickable-poster img {
  cursor: pointer;
  object-fit: cover;
}

.clickable {
  cursor: pointer;
}

.movie-homepage {
  margin-top: 0.3em;
}

.movie-homepage img {
  height: 0.75em;
  transition: all 0.1s ease-in-out;
}

.movie-homepage:hover {
  filter: brightness(110%) saturate(140%);
  transform: scale(1.05);
}

.movie-info {
  padding: 0em 0.6em 0.6em 0.6em;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.img-wrap {
  animation: fadein 1s;
}

.poster-img {
  width: 100%;
}

.poster-placeholder {
  width: 100%;
  opacity: 0.5;
}

.movie-frame h1 {
  margin: 0.3em 0 0 0;
  font-size: 0.9em;
  font-weight: lighter;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.movie-frame h2 {
  opacity: 0.7;
  font-size: 0.7em;
  font-weight: lighter;
  margin: 0.2em 0.2em 0 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.img-fade {
  margin: 0 auto;
  position: relative;
  height: 15em;
  overflow: hidden;
}

.img-fade > .img-wrap:before {
  pointer-events: none;
  content: "";
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 255),
    rgba(0, 0, 0, 0)
  );
  position: absolute;
  height: 8em;
  right: 0;
  bottom: 0;
  left: 0;
}

.img-fade > .img-wrap:after {
  content: "";
  display: block;
  height: 9999px;
}

.img-fade > .img-wrap img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  -ms-interpolation-mode: bicubic;
  object-fit: cover;
}

.movie-name {
  column-gap: 0.3em;
  row-gap: 0.1em;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.movie-flags {
  display: flex;
  flex-wrap: wrap;
  vertical-align: middle;
  column-gap: 0.25em;
  row-gap: 0.1em;
  margin-top: 0.4em;
}

.movie-flags img {
  margin-left: 0.2em;
  height: 0.7em;
  width: 1em;
}

.movie-runtime {
  font-size: 0.6em;
  border: 1px solid rgb(255 255 255 / 20%);
  padding: 0.1em 0.4em 0.1em 0.4em;
  border-radius: 0.3em;
}

.movie-release {
  font-size: 0.6em;
  border: 1px solid rgb(255 255 255 / 20%);
  padding: 0.1em 0.4em 0.1em 0.4em;
  border-radius: 0.3em;
  margin-left: -0.2em;
}

.movie-premiere {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border-radius: 0.3em;
  padding: 0.15em 0.35em;
  background-color: rgba(0, 0, 0, 0.616);
  font-size: 0.7em;
}

.movie-detail {
  overflow-y: auto;
  max-width: 55em;
  height: 100%;
  margin: 0 auto;
  border: 0;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  animation: fadein 1s;
}

@media screen and (min-width: 55em) {
  .movie-detail {
    height: calc(100% - 4em + 1px);
    margin: 2em auto;
    border-radius: 0.5em;
    border: 1px solid rgb(39, 39, 39);
  }
}

@supports (-webkit-touch-callout: none) {
  .movie-detail {
    background-attachment: scroll;
  }
}

.movie-detail-title {
  font-size: 2em;
}

.movie-content {
  margin: 2em;
}

.movie-content a > h1::after {
  content: " ↗";
  font-size: 0.5em;
  font-weight: 600;
  vertical-align: super;
  color: var(--highlight-color)
}


.movie-detail::-webkit-scrollbar, .column-content::-webkit-scrollbar {
  width: 0;
}

.movie-detail::-webkit-scrollbar-track, .column-content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.363);
  border: 10px solid transparent;
  border-radius: 1em;
}

.movie-detail::-webkit-scrollbar-thumb, .column-content::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.432);
}

.movie-detail h1 {
  font-weight: lighter;
}

.movie-detail h2 {
  font-size: 0.7em;
  font-weight: lighter;
  margin: 0 0.2em 0 0;
}

.movie-detail-released {
  font-size: 0.7em;
  opacity: 0.5;
}

.movie-detail-runtime {
  font-size: 0.7em;
  opacity: 0.5;
}

.movie-detail-plot {
  font-weight: lighter;
  font-size: 0.8em;
  margin-bottom: 2em;
}

.movie-detail-plot > h2 {
  font-weight: bolder;
  font-size: 1em;
  margin-bottom: 0.5em;
}

.movie-detail-perf {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.3em;
}

.movie-detail-perf-time {
  width: 3.5em;
  display: inline-block;
  font-variant-numeric: tabular-nums;
}

.movie-detail-perf-day {
  background-color: rgb(180, 180, 180);
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 0.12em 0.4em;
  border-radius: 0.3em;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: lighter;
}

.movie-detail-perf-today {
  background-color: var(--accent-color-2);
  color: white;
}

.movie-detail-perf-block {
  margin-top: 0.5em;
}

.movie-detail-perf-date {
  font-size: 0.75em;
  margin-left: 0.5em;
}

.movie-detail-venue-name {
  font-weight: lighter;
}

.movie-detail-perfs {
  display: grid;
  row-gap: 0.5em;
  margin-top: 0.5em;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
}

.movie-detail-close {
  position: absolute;
  color: white;
  width: 1.2em;
  height: 1.2em;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: bold;
  font-size: 0.75em;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  border: none;
  cursor: pointer;
  margin: 0.4em 0.4em;
  z-index: 10;
  background: none;
}

.movie-detail-close:focus {
  border: none;
  outline: none;
}

.movie-detail-close:hover {
  filter: brightness(110%) saturate(140%);
  transform: scale(1.05);
}

.movie-detail-premiere {
  font-weight: lighter;
  display: inline-block;
  color: black;
  background-color: var(--highlight-color);
  padding: 0.2em 0.3em 0.3em 0.3em;
  border-radius: 0.3em;
}

.credit {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  margin-bottom: 0.5em;
}

.credit-title {
  font-size: 1em;
}

.credit-name {
  font-size: 1em;
  font-weight: lighter;
}

/* trailers */

.trailer-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
}

.trailer-frame {
  border-radius: 0.5em;
  border: solid 1px grey;
}

/* rating */

.rating-list {
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  margin: -0.5em 0 0.6em 0;
  display: flex;
  grid-column-gap: 0.5em;
  position: absolute;
  top: 13.7em;
  right: 0.6em;
}

.rating-list a:after {
  content: "";
}

.rating {
  transition: all 0.1s ease-in-out;
  border-radius: 0.2em;
  padding: 0.1em 0.3em;
  display: flex;
}

.rating:hover {
  filter: brightness(110%) saturate(140%);
  transform: scale(1.05);
}

.rating-database {
  font-size: 0.7em;
}

.rating-value {
  font-size: 0.7em;
  padding-left: 0.1em;
}

.rating-pill {
  border-radius: 0.2em;
  padding: 0.15em 0.25em;
}

/* big rating */
.rating-list-big {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
  row-gap: 0.1em;
  margin-top: 1.5em;
}

.rating-list-big a:after {
  content: "";
}

.rating-big {
  flex-grow: 1;
  text-align: center;
  transition: all 0.1s ease-in-out;
}

.rating-big p {
  margin: 0;
}

.rating-big-value {
  font-size: 1.25em;
}

.rating-big-percent {
  vertical-align: super;
  font-size: 0.5em;
}

.headroom {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.8em;
  gap: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  backdrop-filter: blur(8px);
}

.padding-on-top {
  padding: 0.5em;
}

/* search and sort */
.search-label {
  flex-grow: 1;
  border-radius: 0.4em;
  border: 1px solid rgb(100, 100, 100);
}

.search-query {
  display: flex;
  justify-content: space-between;
  height: 2em;
}

.search-query input {
  color: white;
  height: 1.75em;
  padding: 0 0.5em;
  border: 0;
  border-radius: 0.5em;
  outline: none;
  background: none;
  width: 100%;
  font-size: 1em;
  font-family: "Solway", sans-serif;
}

.search-query::placeholder {
  font-size: 1em;
}

.search-query:focus {
  font-size: 1em;
}

.sort-label {
  display: inline-block;
  border-radius: 0.3em;
  background: none;
  border: 1px solid rgb(100, 100, 100);
  font-size: 1.23em;
  cursor: pointer;
  user-select: none;
  height: 1.65em;
  padding: 0.1em 0.5em 0.2em 0.5em;
}

.sort-label:hover {
  opacity: 1;
  background-color: rgb(151, 151, 151);
}

.sort-label img {
  pointer-events: none;
  object-fit: contain;
  height: 1em;
  vertical-align: middle;
}

.sort-arrow {
  width: 0.5em;
  height: 0.5em;
  margin-left: 0.5em;
  vertical-align: text-top;
}

.sort-button-text {
  font-family: "Solway", sans-serif;
  font-weight: 100;
  color: white;
  padding-left: 0.5em;
  font-size: 0.7em;
  padding-top: 0.2em;  
}

.buttons-container {
  margin-top: 1em;
}

.button-label {
  display: inline-block;
  border-radius: 0.3em;
  background-color: lightgrey;
  border: 0;
  cursor: pointer;
  user-select: none;
  padding: 0.5em 1em 0.5em 1em;
  font-size: 0.5em;
  margin-right: 1em;
}

.button-label:hover {
  opacity: 1;
  background-color: rgb(151, 151, 151);
}

/* overlay */

.overlay-loading {
  z-index: 100;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.577);
}

.overlay-loading img {
  width: 12em;
  border-radius: 0.4em;
  overflow: hidden;
  margin-bottom: 0.5em;
  border: 1px rgba(0, 0, 0, 0.421) solid;
}

@keyframes rotate-image {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay-loading svg {
  vertical-align: middle;
  animation: rotate-image 2s linear infinite;
}

.overlay-loading span {
  padding-left: 0.5em;
  vertical-align: middle;
  font-size: 0.75em;
}


.overlay-loading > div {
  border-radius: 0.6em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.914);
  color: black;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


/* range */
.performance-range {
  color: white;
  min-width: 10em;
  display: inline-flex;
  gap: 0.4em;
  align-items: center;
  flex-grow: 1;
}

.performance-range > img {
  height: 0.9em;
  margin-bottom: -0.15em;
}

/* filtered count */
.movie-filtered-count {
  display: inline-block;
  align-self: center;
  color: white;
}

.movie-filtered-count > img {
  height: 0.9em;
  margin-right: 0.5em;
  margin-bottom: -0.15em;
}

.calendar-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.calendar-background {
  display: inline-block;
  margin: 0.5em;
  border-radius: 0.3em;
  border: solid 1px rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background-color: rgba(194, 194, 194, 0.95);
}

/* home page */
.homepage-menu {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 30em;
  padding: 2em;
}

.vkine-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10em;
  margin-bottom: 3em;
}

.homepage-item {
  margin-bottom: 1em;
}

.homepage-item > button {
  font-weight: lighter;
  color: white;
  height: 1.5em;
  border-radius: 0.3em;
  background-color: rgba(0, 0, 0, 0.582);
  border: 0;
  font-size: 1.2em;
  cursor: pointer;
  user-select: none;
  padding: 0.3em 0.5em 1.7em 0.5em;
  margin: 0;
}

.homepage-item > button:hover {
  background-color: rgba(0, 0, 0, 0.466);
}

.homepage-item > p {
  margin: 0;
}

.popup-trigger {
  background: none;
  border: none;
  border-radius: 0.2em;
  margin-left: auto;
  margin-top: 0.75em;
  margin-right: 1em;
}

.popup-trigger:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.popup-trigger > img {
  width: 1.5em;
  height: 1.5em;
}

.perf-flags {
  display: inline;
  font-size: 0.75em;
  padding-left: 0.75em;
  opacity: 0.3;
}

.perf-flag {
  display: inline;
  padding: 1em 0.1em;
}

.switch-labels {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

.switch-label {
  color: lightgray;
  display: inline;
  border-radius: 0.3em;
  background-color: transparent;
  border: 1px solid rgba(211, 211, 211, 0.5);
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  padding: 0.1em 0.3em;
  margin-top: 0.5em;
}

.switch-label:hover {
  opacity: 1;
  background-color: var(--accent-color);
}

.switch-label-checked {
  background-color: lightgray;
  border: 2px solid white;
  color: black;
}

.popup-content {
  background: black;
  border: none;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.popup-content .switch-label {
  font-size: 0.65em;
}

.genres {
  display: flex;
  font-size: 0.75em;
  column-gap: 0.5em;
  flex-wrap: wrap;
  margin: 0.75em 0 0 0;
}

.genre {
  display: inline;
}

.genres li + li::before {
  content: "|";
  margin: 0 00.5em 0 0;
  opacity: 0.5;
}

.range-info {
  display: flex;
  font-weight: lighter;
  display: inline-block;
  color: black;
  background-color: var(--highlight-color);
  padding: 0.2em 0.3em 0.3em 0.3em;
  border-radius: 0.3em;
  margin-bottom: 1em;
}

.player-wrapper {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

nav ul {
  font-weight: 100;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
}

@media screen and (min-width: 35em) {
  nav ul {
    flex-direction: column;
  }
}

nav ul li {
  display: flex;
  align-items: center;
  gap: 0.75em;
}

nav ul li svg {
  width: 1em;
}

.active-menu-item > svg {
  color: var(--accent-color-2);
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 0.5em;

  font-size: 1.5em;
}

@media screen and (max-width: 35em) {
  .logo-container {
    justify-content: center;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.highlight-text {
  color: var(--highlight-color);
}

.react-player iframe {
  border-radius: 1em;
}

.slider {
  width: 100%;
  height: 1.2em;
}

.slider-track {
  background: var(--accent-color-2);
  border-radius: 0.6em;
  height: 1.2em;
  border: 1px solid rgb(100, 100, 100);
}

.slider-track-0 {
  background: rgb(66, 66, 66);
}

.slider-track-1 {
  height: 1.2em;
  background-color: var(--accent-color-transparent);
}

.slider-thumb {
  width: 3em;
  border-radius: 0.6em;
  text-align: center;
  background: var(--accent-color-2);
  border: 1px solid transparent;
  cursor: grab;
}

.slider-thumb > span {
  margin-top: -0.5em;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;  
  margin-top: -0.1em;
}

.top-movies {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  padding: 1em;
}

.non-clickable-poster {
  overflow: hidden;
  border-radius: 0.8em;
  border: solid 1px rgba(53, 53, 53, 0.796);
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  background-color: rgba(0, 0, 0, 0.45);
}

.home-page > h1 {
  margin-top: 2em;
  text-align: center;
  font-weight: 100;
  font-size: 1.5em;
  opacity: 0.75;
}

@media screen and (min-width: 35em) {
  .home-page > nav {
    display: none;
  }

  #content-page-logo {
    display: none;
  }
}