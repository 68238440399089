:root {
  --accent-color: #000000;
  --accent-color-2: #ff0090;
  --accent-color-transparent: #ff009077;
  --highlight-color: #4abefc;
  --highlight-color-dim: #4abefc63;
  --button-color: rgb(255, 255, 255);
}

.rdp-root {
  --rdp-font-family: "Solway", sans-serif;
  --rdp-cell-size: 1.5em;
  --rdp-accent-color: var(--accent-color);
  --rdp-today-color: var(--accent-color-2);
  --rdp-background-color: gray;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-range_start-date-background-color: var(--highlight-color);
  --rdp-range_end-date-background-color: var(--highlight-color);
  --rdp-range_middle-background-color: var(--highlight-color-dim);
  --rdp-selected-border: 1px solid rgba(0, 0, 0, 0.143);
  padding: 0.5em;
}

.rdp-weekday {
  font-weight: 100;
}

body {
  color: white;
  font-family: "Solway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  background: var(--accent-color);
  margin: 0;
}

li {
  list-style-type: none;
}

ul {
  padding: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

dialog:modal {
  max-width: 100vw;
  min-height: 100dvh;
  padding: 0 !important;
  margin: 0 !important;
  border: 0;
  overflow: auto;
}

@media screen and (min-width: 55em) {
  dialog:modal {
    overflow: hidden;
    min-height: 100dvh;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
  overflow: hidden;
}

.modal::backdrop {
  background-color: black;
  opacity: 0.9;
  overflow: hidden;
}

.hiddenOverflow {
  overflow: hidden;
}

[role="tooltip"].popup-content {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  margin-top: 0.5em;
  border-radius: 0.3em;
  width: auto;
  max-width: 70%;
  font-size: 1.5em;
}

[role="tooltip"].popup-content nav li {
  border-radius: 0.3em;
  padding: 0.3em;
}

[role="tooltip"].popup-content nav li > a {
  display: block;
  width: 100%;
}

[role="tooltip"].popup-content nav li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.day-picker {
  color: black;
}

.day-picker .DayPicker-Day {
  width: 1.75em;
  border-radius: 0 !important;
}
.day-picker .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.day-picker .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rdp-month_caption {
  font-family: "Solway", sans-serif;
  font-weight: 100;
}

.rdp-weekdays {
  font-family: "Solway", sans-serif;
}

.rdp-today {
  color: var(--accent-color-2)
}

a:hover {
  filter: brightness(80%);
}